<template>
  <div class="contact-form">
    <b-form @submit="onSubmit" v-if="show">
      <b-form-group>
        <b-form-input
          type="text"
          placeholder="Name"
          required
          class="input-type-text input-name-name"
          v-model="form.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="email"
          placeholder="E-Mail"
          required
          class="input-type-email input-name-email"
          v-model="form.email"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="text"
          placeholder="Betreff"
          required
          class="input-type-text input-name-subject"
          v-model="form.subject"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-textarea placeholder="Nachricht" required v-model="form.message"></b-form-textarea>
      </b-form-group>
      <button class="btn btn-gradient" type="submit" variant="primary">SENDEN</button>
    </b-form>
  </div>
</template>

<script>
import { EtagenApi } from "@/etagen-api";

export default {
  name: "ContactForm",
  props: {
    to: String,
    toName: String
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        subject: "",
        message: ""
      },
      show: true
    };
  },
  created() {
    // Fill out the form on local development so we don't have to.
    if (process.env.NODE_ENV === "development") {
      this.form = {
        name: "lorem ipsum",
        email: "lorem@ipsum.de",
        subject: "lorem",
        message: "ipsum"
      };
    }
  },
  methods: {
    onSubmit(event) {
      // eslint-disable-next-line no-undef
      gtag("event", "conversion", { send_to: "AW-879092262/YluPCIyT5uICEKbEl6MD" });
      event.preventDefault();
      const data = {
        from: this.form.email,
        fromName: this.form.name,
        subject: this.form.subject,
        message: this.form.message
      };

      if (this.toName !== "") {
        data.toName = this.toName;
      } else {
        data.toName = "Die Etagen GmbH";
        //console.warn("Did not get a recipient name, setting default one.");
      }

      if (this.to !== "") {
        data.to = this.to;
      } else {
        data.to = "info@die-etagen.de";
        //console.warn("Did not get a recipient email address, setting default one.");
      }

      // TODO: Delete this line of code once the website is running on prod.
      // data.to = "drupal@die-etagen.de";

      EtagenApi.callEndpoint("formSubmit/contact", { form: data })
        .then(res => {
          //console.debug(res);
          if (res.status === "OK") {
            this.$bvToast.toast(
              "Vielen Dank für Ihre Anfrage und das damit verbundene Interesse an unserem Unternehmen.\n" +
                "Wir sind bemüht, Ihnen schnellstmöglich zu antworten. ",
              {
                title: "Nachricht erfolgreich gesendet!",
                variant: "warning-color",
                solid: true,
                autoHideDelay: 5000
              }
            );
          } else {
            this.$bvToast.toast(res.message, {
              title: "Fehler!",
              variant: "danger",
              solid: true,
              autoHideDelay: 5000
            });
          }
        })
        .catch(() => {
          this.$bvToast.toast("Es ist ein unerwarteter Fehler aufgetreten", {
            title: "Fehler!",
            variant: "danger",
            solid: true,
            autoHideDelay: 5000
          });
          //console.error(error);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
input{
  min-height: calc(2.5em + 0.75rem + 2px);
}
textarea{
  min-height: calc(4.5em + 1.75rem + 2px);
}
input, textarea{
  background: #272727;
  border: none;
  border-radius: unset;
}
.btn{
  line-height: 2rem;
}
</style>